import { Injectable } from "@angular/core";
import { BaseService } from "../base.service";
import { Concurso } from "src/app/models/concurso/concurso.model";

@Injectable({
  providedIn: "root",
})
export class ConcursoService extends BaseService {
  private relativeUrl: string = "/Concurso/";

  public save(concurso: Concurso) {
    return this._post(this.relativeUrl, concurso);
  }

  public getAll() {
    return this._getAll(this.relativeUrl);
  }

  public getById(id: number) {
    return this._getById(this.relativeUrl, id);
  }

  public getVigente() {
    return this._getAll(this.relativeUrl + "vigente");
  }
}
