import { Injectable } from '@angular/core';
import { Filtro } from 'src/app/models/filtro/filtro.model';
import { LancamentoProfissional } from 'src/app/models/profissional/lancamento-profissional/lancamento-profissional';
import { BaseService } from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class LancamentoProfissionalService extends BaseService {

  private relativeUrl: string = '/LancamentoProfissional/';

  getById(id: number) {
    return this._getById(this.relativeUrl, id);
  }

  getByToken() {
    return this._getAll(this.relativeUrl + 'token')
  }

  getAll(filtro: Filtro) {
    return this._post(this.relativeUrl + 'relatorio', filtro)
  }

  save(lancamento: LancamentoProfissional) {
    return this._post(this.relativeUrl, lancamento);
  }

  updateStatus(lancamento: LancamentoProfissional) {
   return this._post(this.relativeUrl + 'update/status', lancamento)
  }

  delete(id: number) {
    return this._delete(this.relativeUrl, id)
  }

}
