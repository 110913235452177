import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';
import { Observable } from 'rxjs';
import { Projeto } from 'src/app/models/profissional/projetos/projeto.model';
import { CategoriaProjeto } from 'src/app/models/profissional/projetos/categoria-projeto.model';
import { Concurso } from 'src/app/models/concurso/concurso.model';

@Injectable()
export class ProjetoService extends BaseService {
 
  private relativeUrl: string = '/Projeto/';

  public getAll(): Observable<Projeto[]> {
    return this._getAll(this.relativeUrl);
  }

  public getByToken(): Observable<Concurso[]> {
    return this._getByToken(this.relativeUrl + 'profissional');
  }

  public getAllByToken(): Observable<Projeto[]> {
    return this._getByToken(this.relativeUrl + 'profissional/todos');
  }

  public save(projeto: Projeto) {
    return this._post(this.relativeUrl, projeto);
  }

  public delete(id: number) {
    return this._delete(this.relativeUrl, id);
  }

  public saveCategoria(categoria: CategoriaProjeto) {
    return this._post(this.relativeUrl + 'categoria/', categoria)
  }

  public getAllCategorias() {
    return this._getAll(this.relativeUrl + 'categorias')
  }

  public setConcurso(projeto: Projeto) {
    return this._post(this.relativeUrl + 'concurso', projeto)
  } 

  public setVencedor(projeto: Projeto) {
    return this._post(this.relativeUrl + 'setVencedor', projeto)
  }

  public setAprovado(projeto: Projeto) {
    return this._post(this.relativeUrl + 'setAprovado', projeto)
  }
  public setReprovado(projeto: Projeto) {
    return this._post(this.relativeUrl + 'setReprovado', projeto)
  }


}
