import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';
import { Login } from 'src/app/models/login/login.model';
import { Observable } from 'rxjs';
import { Loja } from 'src/app/models/loja/loja/loja.model';

@Injectable()
export class LoginLojaService extends BaseService {

  private relativeUrl: string = '/Loja/';

  public login(login: Login) : Observable<Loja> {
    return this._post(this.relativeUrl + 'login', login);
  }

}
