import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/models/sistema/usuario/usuario.model';
import { Login } from 'src/app/models/login/login.model';

@Injectable()
export class UsuarioService extends BaseService {
  
  private relativeUrl: string = '/Usuario/';

  public getAll(): Observable<Usuario[]> {
    return this._getAll(this.relativeUrl);
  }

  public getById(id: number): Observable<Usuario> {
    return this._getById(this.relativeUrl, id);
  }

  public save(usuario: Usuario) {
    return this._post(this.relativeUrl, usuario);
  }

  public delete(id: number) {
    return this._delete(this.relativeUrl, id);
  }

  public login(login: Login) {
      return this._post(this.relativeUrl + 'login', login)
  }
}
