import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';
import { Login } from 'src/app/models/login/login.model';
import { Profissional } from 'src/app/models/profissional/profissional/profissional.model';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService extends BaseService {

  private relativeUrl: string = '/Profissional/';

  public login(login: Login) : Observable<Profissional> {
    return this._post(this.relativeUrl + 'login', login);
  }

}
