import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Bonus } from 'src/app/models/bonus/bonus';

@Injectable()
export class BonusService extends BaseService {

  private relativeUrl: string = '/Bonus/';

  public save(bonus: Bonus) {
    return this._post(this.relativeUrl, bonus)
  }

  public updateDate(bonus: Bonus) {
    return this._post(this.relativeUrl + 'update/date', bonus)
  }

  public delete(id: number) {
    return this._delete(this.relativeUrl, id)
  }

  public getAll() {
    return this._getAll(this.relativeUrl)
  }

  public getResumoByIdProfissional() {
    return this._getAll(this.relativeUrl + 'bonus/resumo')
  }

  public getByIdProfissional(id: number) {
    return this._getById(this.relativeUrl, id)
  }
}
