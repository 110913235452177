import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';
import { Observable } from 'rxjs';
import { FotoLoja } from 'src/app/models/loja/foto/foto.model';

@Injectable()
export class FotoService extends BaseService {
  
  private relativeUrl: string = '/Foto/';

  public getAll(): Observable<FotoLoja[]> {
    return this._getAll(this.relativeUrl);
  }

  public save(foto: FotoLoja) {
    return this._post(this.relativeUrl, foto);
  }

  public delete(id: number) {
      return this._delete(this.relativeUrl, id)
  }

}
