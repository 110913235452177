import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Jurado } from 'src/app/models/jurado/jurado.model';
import { Voto } from 'src/app/models/jurado/voto.model';
import { Login } from 'src/app/models/login/login.model';
import { Projeto } from 'src/app/models/profissional/projetos/projeto.model';
import { Filtro } from 'src/app/models/filtro/filtro.model';

@Injectable()
export class JuradoService extends BaseService {

  private relativeUrl: string = '/Jurado/';

  public save(jurado: Jurado) {
    return this._post(this.relativeUrl, jurado);
  }

  public getAll() {
    return this._getAll(this.relativeUrl)
  }

  public getById(id: number) {
    return this._getById(this.relativeUrl, id)
  }

  public addVoto(projeto: Projeto) {
      return this._post(this.relativeUrl + 'voto', projeto)
  }

  public delete(id: number){
      return this._delete(this.relativeUrl, id)
  }

  public login(login: Login) {
    return this._post(this.relativeUrl + 'login', login)
  }

  public getProjetos(filtro: Filtro) {
    return this._post(this.relativeUrl + 'projetos', filtro)
  }

  public checkVoto() {
    return this._getAll(this.relativeUrl + 'checkVoto')
  }
}
