import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';
import { Observable } from 'rxjs';
import { ResgatePremio } from 'src/app/models/premio/resgate-premio';

@Injectable()
export class RelatorioResgatePremiosService extends BaseService {
  
  private relativeUrl: string = '/ResgatePremio/';

  public getAll(): Observable<ResgatePremio[]> {
    return this._getAll(this.relativeUrl);
  }

  public getAllByIdProfissional(): Observable<ResgatePremio[]> {
    return this._getByToken(this.relativeUrl + 'profissional');
  }
  
  public save(resgate: ResgatePremio) {
      return this._post(this.relativeUrl, resgate)
  }

  public delete(id: number) {
      return this._delete(this.relativeUrl, id)
  }

}
