import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable()
export class CidadeService extends BaseService {

  private relativeUrl: string = '/Cidades/';

  public getEstados(): Observable<any[]> {
    return this._getAll(this.relativeUrl + 'estados');
  }
  
  public getCidades(estado): Observable<any[]> {
    return this._getAll(this.relativeUrl + 'cidades/' + estado);
  }

}
